import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { ModalService } from '@app/shared/services/modal.service';
import { UserService } from '@app/shared/services/user.service';
import { BaseModalComponent } from '@shared/components/base-modal/base-modal.component';

@Component({
    selector: 'app-terms-and-privacy-modal',
    templateUrl: 'terms-and-privacy-modal.component.html',
    styleUrls: ['terms-and-privacy-modal.component.scss'],
})

export class TermsAndPrivacyComponent extends BaseModalComponent implements OnInit {
    public agreeToTermsAndPrivacy = false;

    constructor(
        public userService: UserService,
        public modalService: ModalService,
        public authService: AuthService,
        public route: ActivatedRoute,
    ){
        super();
    }

    toggleAgreeToTermsAndPrivacy() {
        this.agreeToTermsAndPrivacy = !this.agreeToTermsAndPrivacy;
    }

    confirm() {
        const modalData = this.modalService.modalData.value;
        const isClient = modalData && modalData.token;

        this.userService.agreeToTermsAndPrivacy(modalData?.token)
            .subscribe(() => {
                if (!isClient) {
                    this.authService.me().subscribe();
                }
                this.modalService.destroy();
            });
    }
}
