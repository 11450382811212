import { NgModule } from '@angular/core';
import { BaseModalComponent } from './components/base-modal/base-modal.component';
import { DomService } from './services/dom.service';
import { ModalService } from './services/modal.service';
import { UserService } from './services/user.service';
import { SearchComponent } from './components/search/search.component';
import { ImageCropModalComponent } from './components/image-crop-modal/image-crop-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { SecurePipe } from '@shared/pipes/secure.pipe';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { DropdownButtonComponent } from '@shared/components/dropdown-button/dropdown-button.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MeasurementService } from '@shared/services/measurement.service';
import { CustomFactorSelectComponent } from './components/custom-factor-select/custom-factor-select.component';
import { CustomSidebarSelectComponent } from './components/custom-sidebar-select/custom-sidebar-select.component';
import { SidebarDropdownComponent } from './components/sidebar-dropdown/sidebar-dropdown.component';
import { SidebarTabComponent, SidebarTabsComponent } from './components/sidebar-tabs/sidebar-tabs.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { ColorService } from './services/color.service';
import { TooltipDirective } from './directives/tooltip.directive';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { TeamsRightsSelectComponent } from './components/team-rights-select/teams-rights-select.component';
import { PermissionsSelectComponent } from '@shared/components/permissions-select/permissions-select.component';
import { LogoutComponent } from '@app/shared/components/logout/logout.component';
import { StopMeasurementModalComponent } from '@shared/components/stop-measurement-modal/stop-measurement-modal.component';
import { SingleSelectComponent } from '@shared/components/single-select/single-select.component';
import { EditQuestionModalComponent } from '@shared/components/edit-question-modal/edit-question-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {QuestionCheckComponent} from '@shared/components/prompt-title-body-modal/question-check.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faGrid2, faRadar, faListCheck, faHeartPulse, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { TermsAndPrivacyComponent } from '@shared/modals/terms-and-privacy/terms-and-privacy-modal.component';

@NgModule({
    imports: [
        ImageCropperModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        PortalModule,
        MatSlideToggleModule,
        FontAwesomeModule,
        TranslateModule
    ],
    declarations: [
        BaseModalComponent,
        QuestionCheckComponent,
        SearchComponent,
        ImageCropModalComponent,
        LoaderComponent,
        SecurePipe,
        DropdownComponent,
        DropdownButtonComponent,
        CustomFactorSelectComponent,
        CustomSidebarSelectComponent,
        SidebarDropdownComponent,
        SidebarTabComponent,
        SidebarTabsComponent,
        PromptModalComponent,
        EditQuestionModalComponent,
        StopMeasurementModalComponent,
        TooltipDirective,
        DropdownMenuComponent,
        TeamsRightsSelectComponent,
        PermissionsSelectComponent,
        SingleSelectComponent,
        LogoutComponent,
        TermsAndPrivacyComponent,
    ],
    entryComponents: [
        BaseModalComponent,
        ImageCropModalComponent,
        PromptModalComponent,
        EditQuestionModalComponent,
    ],
    providers: [
        DomService,
        ModalService,
        UserService,
        MeasurementService,
        ColorService,
        SecurePipe,
    ],
    exports: [
        BaseModalComponent,
        QuestionCheckComponent,
        SearchComponent,
        ImageCropModalComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderComponent,
        SecurePipe,
        DropdownComponent,
        DropdownButtonComponent,
        OverlayModule,
        PortalModule,
        CustomFactorSelectComponent,
        CustomSidebarSelectComponent,
        SidebarDropdownComponent,
        SidebarTabComponent,
        SidebarTabsComponent,
        PromptModalComponent,
        EditQuestionModalComponent,
        StopMeasurementModalComponent,
        TooltipDirective,
        DropdownMenuComponent,
        TeamsRightsSelectComponent,
        PermissionsSelectComponent,
        SingleSelectComponent,
        LogoutComponent,
        TranslateModule,
        TermsAndPrivacyComponent,
    ]
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faGrid2, faRadar, faListCheck, faHeartPulse, faCalendar);
    }
}
