import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CockpitService } from '@app/modules/cockpit/services/cockpit.service';
import { Customer } from '@shared/models/customer';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@shared/models/user';
import { CustomerService } from '@shared/services/customer.service';
import { environment } from '@environments/environment';
import { SecurePipe } from '@shared/pipes/secure.pipe';
import {ModalService} from '@shared/services/modal.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    customer: Customer;
    currentLogo: any;
    user: User;

    constructor(
        private authService: AuthService,
        private cockpitService: CockpitService,
        private router: Router,
        private customerService: CustomerService,
        private securePipe: SecurePipe,
        private modalService: ModalService,
    ) {
        this.authService.user.subscribe((user) => {
            this.user = user;
        });

        this.router.events.subscribe((next) => {
            if (next instanceof NavigationEnd) {
                if (next.url.indexOf('cockpit') > -1) {
                    this.retrieveCockpitCustomer();
                }
            }
        });

        this.customerService.customer
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((customer) => {
                this.customer = customer;
                if (customer) {
                    const url = `${environment.api_url}/customers/${customer.id}/logo`;
                    this.securePipe.transform(url).pipe().subscribe(res => {
                        this.currentLogo = res;
                    });
                } else {
                    this.currentLogo = null;
                }
            });
    }

    retrieveCockpitCustomer() {
        this.cockpitService.customer
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((customer) => {
                this.customer = customer;
                if (customer && !this.currentLogo) {
                    this.cockpitService.customerLogoObservable.subscribe(logo => {
                        this.currentLogo = logo;
                    });
                }
            });
    }

    get logo() {
        return this.currentLogo || '/assets/images/logo.svg';
    }

    async logout() {
        await this.router.navigate(['/']);
        if (this.modalService.modalOpen) {
            return;
        }

        this.authService.logout();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
