import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {ModalService} from '@shared/services/modal.service';
import {BaseModalComponent} from '@shared/components/base-modal/base-modal.component';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-questions',
    templateUrl: './question-check.component.html',
    styleUrls: ['./question-check.component.scss']
})
export class QuestionCheckComponent extends BaseModalComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public title = '';
    public body = '';
    public confirm = '';
    public deny = '';

    constructor(
        private modalService: ModalService,
    ) {
        super();
    }


    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.modalService.modalData
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.title = data.title;
                this.body = data.body;
                this.deny = data.deny;
                this.confirm = data.confirm;
            });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    closePrompt(value) {
        this._afterClosed.next(value);
    }
}
