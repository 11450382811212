import { Component, OnDestroy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface ChangeEvent {
    value: any;
    itemValue: any;
}

@Component({
    selector: 'app-teams-rights-select',
    templateUrl: 'teams-rights-select.component.html',
    styleUrls: ['teams-rights-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TeamsRightsSelectComponent),
            multi: true
        }
    ]
})
export class TeamsRightsSelectComponent implements ControlValueAccessor, OnDestroy {
    public rights = [];

    constructor(
        private authService: AuthService,
        private translateService: TranslateService
    ) {
        this.authService.user.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((user) => {
                if (!user.is_admin) {
                    this.rights.splice(0, 1);
                }
            });

        this.translateService.get([
            'CUSTOMERS.USERS.RIGHTS.COCKPIT',
            'CUSTOMERS.USERS.RIGHTS.TEAM_MEMBER',
            'CUSTOMERS.USERS.RIGHTS.COORDINATOR'
        ]).subscribe((translations) => {
            this.rights = [
                { label: translations['CUSTOMERS.USERS.RIGHTS.COCKPIT'], value: 'is_cockpit_user' },
                { label: translations['CUSTOMERS.USERS.RIGHTS.TEAM_MEMBER'], value: 'is_user' },
                { label: translations['CUSTOMERS.USERS.RIGHTS.COORDINATOR'], value: 'is_coordinator' }
            ];
        });
    }
    @Input() teams: any[] = [];
    @Input() teamLabel = 'label';
    @Input() dataKey = 'value';
    @Input() disable: false;

    private ngUnsubscribe: Subject<void> = new Subject<void>();

    public value: any[] = [];
    public onModelChange = (val) => {};
    public onModelTouched = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn): void {
        this.onModelTouched = fn;
    }

    getTeamLabel(team) {
        return team[this.teamLabel];
    }

    getTeamValue(team) {
        return team[this.dataKey];
    }

    onTeamClick(team) {
        if (this.disable) {
            return;
        }
        const teamValue = this.getTeamValue(team);

        if (this.isTeamSelected(team)) {
            const index = this.value.findIndex((obj) => this.getTeamValue(obj) === this.getTeamValue(team));
            this.value.splice(index, 1);
        } else {
            const newTeam = {
                [this.dataKey]: teamValue
            };
            this.rights.forEach(right => newTeam[right.value] = false);
            this.value = [...this.value || [], newTeam];
        }

        this.onModelChange(this.value);
    }

    onRightClick(team, right) {
        const selectedTeam = this.value.find(obj => this.getTeamValue(obj) === this.getTeamValue(team));
        if (selectedTeam) {
            selectedTeam[right.value] = !selectedTeam[right.value];
        }
    }

    isTeamSelected(team) {
        if (!this.value) {
            return false;
        }
        const teamValue = this.getTeamValue(team);
        return this.value.find(obj => this.getTeamValue(obj) === teamValue);
    }

    isRightSelected(team, right) {
        if (!this.value) {
            return false;
        }
        const selectedTeam = this.value.find(obj => this.getTeamValue(obj) === this.getTeamValue(team));
        if (!selectedTeam) {
            return false;
        }

        return selectedTeam[right.value];
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
