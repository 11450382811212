import { SecurePipe } from '@shared/pipes/secure.pipe';
import { finalize, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@app/shared/services/loader.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Quad } from '../models/quad';
import { Polar } from '../models/Polar';
import { Activity } from '../models/Activity';
import { MeasurementOverview } from '@app/modules/cockpit/models/MeasurementOverview';
import { AuthService } from '@shared/services/auth.service';
import { Team } from '@shared/models/team';
import { Customer } from '@shared/models/customer';
import { Heartbeat } from '../models/Heartbeat';
import { TeamService } from '@app/shared/services/team.service';
import { CustomerService } from '@app/shared/services/customer.service';

export interface CockpitResponse {
    teams: Team[];
    tps_average: number;
    teams_count: number;
    customer: Customer;
}

@Injectable({
    providedIn: 'root',
})
export class CockpitService {
    private url = `${environment.api_url}/cockpit`;

    public customer = new BehaviorSubject<Customer>(null);
    public teamsObservable = new BehaviorSubject<Team[]>(null);
    public filterTeamsObservable = new BehaviorSubject<Team[]>(null);
    public customerLogoObservable = new BehaviorSubject<any>(null);
    public averageTpsObservable = new BehaviorSubject<number>(null);
    public teams: Team[];
    public customerLogo = null;
    public quadObservable: Observable<Quad>;
    public polarObservable: Observable<Polar>;
    public activityObservable: Observable<Activity>;
    public measurementObservable: Observable<MeasurementOverview>;
    public heartbeatObservable: Observable<Heartbeat>;
    public customerId = null;

    constructor(
        private http: HttpClient,
        private loaderService: LoaderService,
        private securePipe: SecurePipe,
        private authService: AuthService,
        private teamService: TeamService,
        private customerService: CustomerService,
    ) {
        this.customerId = parseInt(localStorage.getItem('cockpit-customer-id') || null, 10);

        this.authService.user.subscribe((user) => {
            if (user && !user.is_admin && user.customers.filter(customer => customer.show_cockpit_rule).length === 1) {
                const customer = user.customers.filter(c => c.show_cockpit_rule)[0];
                this.customerId = customer.id;
                localStorage.setItem('cockpit-customer-id', customer.id.toString());
                this.customer.next(customer);
            }
        });

        this.init();
    }

    init() {
        this.customer.subscribe(customer => {
            if (customer) {
                this.getLogo(customer.id);
                this.teamService.fetch(customer.id);
            }


            if (customer && customer.id !== this.customerId) {
                this.customerId = parseInt(localStorage.getItem('cockpit-customer-id') || null, 10);
            }
        });

        if (this.customer.getValue() === null && this.customerId) {
            this.customerService.getCustomer(this.customerId, false)
                .subscribe(res => {
                    this.customer.next(res.customer);
                });
        }

        this.teamService.teams.subscribe(teams => {
            const cockpitTeams = teams.filter(team => {
                if (team.pivot) {
                    return team.pivot.is_cockpit_user;
                }

                return true;
            });

            this.setSelectedTeams(cockpitTeams);
            this.teamsObservable.next(cockpitTeams);
            this.teams = cockpitTeams;
        });
    }

    setSelectedTeams(teams: Team[]) {
        const selectedTeams = JSON.parse(localStorage.getItem('cockpit-selected'));
        if (selectedTeams) {
            selectedTeams.forEach(teamId => {
                const team = teams.find(t => t.id === teamId);
                if (team) {
                    team.selected = true;
                }
            });
        }
    }

    getTeams(withFilters = false, allTeams = true) {
        return this.http.get<CockpitResponse>(this.url, {
            params: this.getParams(withFilters, allTeams)
        }).subscribe(result => {
            const teams = result.teams;

            if (withFilters) {
                this.averageTpsObservable.next(result.tps_average);
            } else {
                this.teams = teams;
                this.setSelectedTeams(this.teams);
                this.teamsObservable.next(this.teams);
                this.filterTeamsObservable.next(this.teams);
            }
        });
    }

    getFilteredTeams() {
        return this.http.get<CockpitResponse>(this.url, {params: this.getParams(true)});
    }

    getLogo(id) {
        const url = `${environment.api_url}/customers/${id}/logo`;
        return this.securePipe.transform(url).pipe().subscribe(res => {
            this.customerLogo = res;
            this.customerLogoObservable.next(res);
        });
    }

    toggleSelectedTeam(teamId: number) {
        const {teams} = this;

        const selectedTeam = teams.find(team => team.id === teamId);
        if (selectedTeam) {
            selectedTeam.selected = !selectedTeam.selected;
        }

        const selectedTeams = teams.filter(team => team.selected);
        const selectedTeamIds = selectedTeams.map(team => team.id);
        localStorage.setItem('cockpit-selected', JSON.stringify(selectedTeamIds));
        this.teamsObservable.next(teams);
        this.filterTeamsObservable.next(selectedTeams);
    }

    getQuads(force = false): Observable<Quad> {
        if (force) {
            this.quadObservable = null;
        }
        if (this.quadObservable) {
            return this.quadObservable;
        } else {
            const url = `${environment.api_url}/cockpit/quad`;
            this.loaderService.start();
            this.quadObservable = this.http.get<Quad>(url, {params: this.getParams()}).pipe(
                finalize(() => this.loaderService.stop()),
                shareReplay(1),
            );
            return this.quadObservable;
        }
    }

    getPolars(force = false) {
        if (force) {
            this.polarObservable = null;
        }
        if (this.polarObservable) {
            return this.polarObservable;
        } else {
            const url = `${environment.api_url}/cockpit/polar`;

            this.loaderService.start();
            this.polarObservable = this.http.get<Polar>(url, {params: this.getParams()}).pipe(
                finalize(() => this.loaderService.stop()),
                shareReplay(1),
            );
            return this.polarObservable;
        }
    }


    getActivity(force = false) {
        if (force) {
            this.activityObservable = null;
        }
        if (this.activityObservable) {
            return this.activityObservable;
        } else {
            const url = `${environment.api_url}/cockpit/activity`;

            this.loaderService.start();
            this.activityObservable = this.http.get<Activity>(url, {params: this.getParams(true, true)}).pipe(
                finalize(() => this.loaderService.stop()),
                shareReplay(1),
            );
            return this.activityObservable;
        }
    }


    getMeasurementOverview(force = false) {
        if (force) {
            this.measurementObservable = null;
        }

        if (this.measurementObservable) {
            return this.measurementObservable;
        } else {
            const url = `${environment.api_url}/cockpit/measurements?customer=${this.customerId}&get_all=1`;

            this.loaderService.start();
            this.measurementObservable = this.http.get<MeasurementOverview>(url).pipe(
                finalize(() => this.loaderService.stop()),
                shareReplay(1),
            );
            return this.measurementObservable;
        }
    }

    getHeartbeat(force = false) {
        if (force) {
            this.heartbeatObservable = null;
        }

        if (this.heartbeatObservable) {
            return this.heartbeatObservable;
        } else {
            const url = `${environment.api_url}/cockpit/heartbeat?customer=${this.customerId}&get_all=1`;

            this.loaderService.start();
            this.heartbeatObservable = this.http.get<Heartbeat>(url, {params: this.getParams()}).pipe(
                finalize(() => this.loaderService.stop()),
                shareReplay(1),
            );
            return this.heartbeatObservable;
        }
    }

    private getParams(withFilters = true, getAll = true) {
        let params = new HttpParams();
        params = params.append('customer', this.customerId.toString());
        params = params.append('get_all', (+getAll).toString());

        if (withFilters) {
            const selectedTeams = this.teams?.filter(team => team.selected);
            if (selectedTeams?.length > 0) {
                const ids = selectedTeams.map(team => team.id);
                ids.forEach(id => {
                    params = params.append('team_ids[]', id.toString());
                });
            }
        }

        return params;
    }
}
