import {
    Component,
    OnInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    AfterViewInit,
    Renderer2,
    ElementRef,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { CockpitService } from '@app/modules/cockpit/services/cockpit.service';
import { Customer } from '@shared/models/customer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '@shared/models/user';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';

interface DropdownMenuOption {
    label: string;
    route: string;
    icon: string;
    permissionId: string;
}

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: 'dropdown-menu.component.html',
    styleUrls: ['dropdown-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DropdownMenuComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() options: DropdownMenuOption[];
    @Input() disabled = false;
    @Output() changed = new EventEmitter<string>();

    @ViewChild('menuoptions')
    private menuOptionsEl: ElementRef;
    private hideTimer: any;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private user: User;
    public filteredOptions: DropdownMenuOption[];

    @ViewChild('dropdownComp')
    public dropdown: ElementRef;
    public isCockpit = false;
    public customer: Customer;

    constructor(
        private renderer: Renderer2,
        private router: Router,
        private cockpitService: CockpitService,
        private cd: ChangeDetectorRef,
        private authService: AuthService
    ) {
        this.authService.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
            this.user = user;
            this.checkPermissions();
        });
    }

    ngOnInit() {
        this.router.events.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(() => {
            this.isCockpit = /cockpit/.test(this.router.url);
        });
    }

    ngAfterViewInit() {
        this.cockpitService.customer.pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe(customer => {
            if (!this.disabled) {
                this.checkCockpit();
            }
            this.customer = customer;
            this.checkPermissions();
        });
    }

    private checkPermissions() {
        if (this.customer && this.user) {
            this.filteredOptions = this.options.filter(option => {
                return this.user.is_admin ? true : this.customer.permissions[option.permissionId];
            });
            this.cd.detectChanges();
        }
    }

    checkCockpit() {
        if (this.isCockpit) {
            this.renderer.addClass(this.menuOptionsEl.nativeElement, 'cockpit-menu');
        } else {
            this.renderer.removeClass(this.menuOptionsEl.nativeElement, 'cockpit-menu');
        }
    }

    public showDropdown() {
        if (this.disabled) {
            return false;
        }
        this.renderer.addClass(this.dropdown.nativeElement, 'show');
    }

    public hideDropdown() {
        if (this.disabled) {
            return false;
        }
        this.hideTimer = setTimeout(() => {
            this.renderer.removeClass(this.dropdown.nativeElement, 'show');
        }, 200);
    }

    public removeHideTimer() {
        clearTimeout(this.hideTimer);
    }

    public onOptionSelect(event: MouseEvent, option) {
        event.stopPropagation();
        this.changed.emit(option);
        this.hideDropdown();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

