import { Component, Input, ViewChild, forwardRef, AfterViewChecked } from '@angular/core';
import { DropdownComponent } from '@shared/components/dropdown/dropdown.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-sidebar-dropdown',
    templateUrl: './sidebar-dropdown.component.html',
    styleUrls: ['./sidebar-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SidebarDropdownComponent),
            multi: true,
        }
    ]
})
export class SidebarDropdownComponent implements ControlValueAccessor, AfterViewChecked {
    @Input() options: object[];
    @Input() displayKey: string;
    @Input() valueKey: string;
    @Input() placeholder = 'Selecteer optie';
    @Input() background: string;

    public value: string;
    public displayValue: string;

    @ViewChild(DropdownComponent)
    public dropdown: DropdownComponent;

    constructor() {}

    ngAfterViewChecked() {
        if (this.value) {
            const selectedOption = this.options.find(o => o[this.valueKey] === this.value);

            if (!selectedOption) {
                this.displayValue = this.value;

                return;
            }

            this.value = selectedOption[this.valueKey];
            this.displayValue = selectedOption[this.displayKey];

            return;
        }

        this.displayValue = this.placeholder;
    }

    onChange: any = () => {

    }
    onTouch: any = () => {};

    writeValue(value: any){
        this.value = value;
    }

    registerOnChange(fn: any){
        this.onChange = fn;
    }

    registerOnTouched(fn: any){
        this.onTouch = fn;
    }

    public showDropdown() {
        this.dropdown.show();
    }

    public hideDropdown() {
        this.dropdown.hide();
    }

    public onOptionSelect(option) {
        this.value = option;
        this.displayValue = this.options.find(o => o[this.valueKey] === option)[this.displayKey];
        this.hideDropdown();
        this.onChange(this.value);
    }

    public trackByValue(index: number) {
        return index;
    }
}
